import React from 'react'
import { DateSelect, CreateFile, Dropdown, Checkbox } from 'common'
import { IStaffRow } from 'models'

type HighRiskWorksProps = {
	values: {
		high_risk_works_file: string
		high_risk_works_date: string
		high_risk_works_expiry_date: string
		high_risk_works_uploaded_by: string | null
		high_risk_works_class: string
	}

	staff: IStaffRow[] | undefined
	setFieldValue: (
		field: string,
		value: string | number | boolean | Date
	) => void
	setFieldTouched: (field: string, touched?: boolean) => void
}

const HighRiskOptions = [
	{ value: 'Scaffolding Basic', label: 'Scaffolding Basic' },
	{ value: 'Scaffolding Intermediate', label: 'Scaffolding Intermediate' },
	{ value: 'Scaffolding Advanced', label: 'Scaffolding Advanced' },
	{ value: 'Rigging Basic', label: 'Rigging Basic' },
	{ value: 'Rigging Intermediate', label: 'Rigging Intermediate' },
	{ value: 'Rigging Advanced', label: 'Rigging Advanced' },
	{ value: 'Dogging', label: 'Dogging' },
	{ value: 'Forklift', label: 'Forklift' },
	{ value: 'EWP', label: 'EWP' },
	{ value: 'Materials Hoist', label: 'Materials Hoist' },
	{ value: 'Personnel Hoist', label: 'Personnel Hoist' },
	{ value: 'Vehicle Loading Crane', label: 'Vehicle Loading Crane' },
	{ value: 'CN', label: 'CN' },
	{ value: 'C2', label: 'C2' },
	{ value: 'C6', label: 'C6' },
	{ value: 'C1', label: 'C1' },
	{ value: 'CO', label: 'CO' },
]

export const HighRiskWorks: React.FC<HighRiskWorksProps> = ({
	values,
	staff,
	setFieldValue,
	setFieldTouched,
}) => {
	const renderStaffList = (): { label: string; value: string }[] => {
		if (staff && staff?.length > 0) {
			return staff.map((item) => ({
				label: item.staff_name,
				value: item.id || '',
			}))
		}
		return []
	}
	return (
		<>
			<h3 className="px-4 py-2 text-lg font-semibold leading-5 ">
				High Risk Work Licence
			</h3>
			<div>
				<div className="flex items-center px-2">
					<DateSelect
						title="Date"
						id="high_risk_works_date"
						value={values.high_risk_works_date}
						onChange={setFieldValue}
					/>
					<DateSelect
						title="Expiry Date"
						id="high_risk_works_expiry_date"
						value={values.high_risk_works_expiry_date}
						onChange={setFieldValue}
					/>
				</div>
				<div className="w-1/2 pl-2">
					<h4 className="text-md font-normal leading-5 px-2">File</h4>
					<CreateFile
						field="high_risk_works_file"
						setFieldValue={setFieldValue}
						value={values.high_risk_works_file}
					/>
				</div>
				<Checkbox
					title="High Risk Work Licence Class"
					options={HighRiskOptions}
					id="high_risk_works_class"
					values={JSON.parse(values.high_risk_works_class)}
					onChange={(id: string, values: string[]) => {
						setFieldValue(id, JSON.stringify(values))
					}}
					columns={3}
				/>
				<div className="w-1/2 pl-2">
					<Dropdown
						label="Uploaded By"
						id="high_risk_works_uploaded_by"
						value={values.high_risk_works_uploaded_by || ''}
						onChangeVal="label"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						options={renderStaffList()}
					/>
				</div>
			</div>
		</>
	)
}
