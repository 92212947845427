import moment from 'moment'

export const columns = [
	{
		header: 'Created Date',
		field: 'createdDate',
	},
	{
		header: 'File Type',
		field: 'fileType',
	},
	{
		header: 'File Description',
		field: 'fileDescription',
	},
	{
		header: 'Notes',
		field: 'notes',
	},
	{
		header: 'File',
		field: 'file',
		body: (rowData: { file: string }) => <a href={rowData.file}>Link</a>,
	},
]

export const vehicleFileColumns = [
	{
		header: 'Created Date',
		field: 'created_at',
		body: (row: { created_at: Date }) => {
			return row.created_at ? moment(row.created_at).format('DD/MM/YYYY') : ''
		},
	},
	{
		header: 'File Type',
		field: 'file_type',
	},
	// {
	// 	header: 'File Description',
	// 	field: 'file_description',
	// },
	{
		header: 'Uploaded By',
		field: 'uploaded_by',
	},
	{
		header: 'Notes',
		field: 'notes',
	},
	{
		header: 'File',
		field: 'file',
		body: (rowData: { link: string }) => <a href={rowData.link}>Link</a>,
	},
]
