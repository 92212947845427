import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'

export const useApproveLeave = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { postRequest } = useApi()
	const queryClient = useQueryClient()

	const approveLeave = async (leaveId: number, approvedBy: string) => {
		try {
			const response = await postRequest(
				AppRoutes.serverLeavesRoutes.approveLeave,
				{
					leaveId,
					status: 'Approved',
					approvedBy,
				},
				leaveId
			)
			queryClient.invalidateQueries()
			showSuccess('Leave updated successfully')
			return response
		} catch (error) {
			showError('Something went wrong updating Leave')
			console.error(error)
			throw new Error((error as Error).message)
		}
	}
	return { approveLeave }
}
