import { Table } from 'common'
import moment from 'moment'
import { VisitTimesheetsServices } from 'services'

interface VisitsTimesheetsTableProps {
	job_id?: number
}
export const VisitsTimesheetsTable = ({
	job_id,
}: VisitsTimesheetsTableProps) => {
	const { data: timesheetData, isLoading: timesheetLoading } =
		VisitTimesheetsServices.useVisitTimesheetsByJobId(job_id)
	const columns = [
		{
			field: 'createdAt',
			header: 'Date',
			body: (row: { createdAt: Date }) => {
				return row.createdAt ? moment(row.createdAt).format('DD/MM/YYYY') : ''
			},
		},
		{ field: 'time_in', header: 'Time In' },
		{ field: 'time_off', header: 'Time Off' },
		{
			field: '',
			header: '#Staff',
			body: (row: { staff_ids: string[] }) => {
				return String(row?.staff_ids?.length+1)
			},
		},
		{
			field: '',
			header: 'Total Hours',
			body: (row: { time_in: string; time_off: string, staff_ids: string[] }) => {
				const start = moment(row.time_in, 'HH:mm')
				const finish = moment(row.time_off, 'HH:mm')
				if (row.time_off && finish) {
					const duration = moment.duration(finish.diff(start))
					const hours = (duration.asHours() * (row?.staff_ids?.length+1))
					return <span>{hours.toFixed(2)}</span>
				}
				return <span />
			},
		},
	]

	return (
		<>
			<Table
				columns={columns}
				data={timesheetData}
				isLoading={false}
				title="Visits Timesheets"
				disableButtons
			/>
		</>
	)
}
