import { Input, SideModal, Spinner, TextArea, TimeSelect } from 'common'
import { useFormik } from 'formik'
import { TimesheetServices } from 'services'
import * as Yup from 'yup'

interface IProps {
	timesheet_id: number | null
	heading: string
	setOpen: (open: boolean) => void
	open: boolean
}

interface IinitialValues {
	time_on: string
	time_off: string
	comments: string
	lunch_break: number
	hours1: number
	comments1: string
	hours2: number
	comments2: string
	hours3: number
	comments3: string
}

export const EditTimesheet = ({
	timesheet_id,
	heading,
	setOpen,
	open,
}: IProps) => {
	const { data: timesheetData, isLoading: timesheetLoading } =
		TimesheetServices.useTimesheetById(timesheet_id)

	const { updateTimesheet } = TimesheetServices.useUpdateTimesheetById()

	const initialValues: IinitialValues = {
		time_on: timesheetData?.time_on || '',
		time_off: timesheetData?.time_off,
		comments: timesheetData?.comments || '',
		lunch_break: timesheetData?.lunch_break || 0,
		hours1: timesheetData?.hours1 || 0,
		comments1: timesheetData?.comments1 || '',
		hours2: timesheetData?.hours2 || 0,
		comments2: timesheetData?.comments2 || '',
		hours3: timesheetData?.hours3 || 0,
		comments3: timesheetData?.comments3 || '',
	}

	const validationSchema = Yup.object({
		lunch_break: Yup.number()
			.required('Lunch Break is required')
			.typeError('Lunch Break must be a number'),
	})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			const payload = [
				{
					id: timesheet_id,
					comments: values.comments,
					time_on: values.time_on,
					time_off: values.time_off,
					lunch_break: values.lunch_break,
					hours1: values.hours1,
					comments1: values.comments1,
					hours2: values.hours2,
					comments2: values.comments2,
					hours3: values.hours3,
					comments3: values.comments3
				},
			]

			updateTimesheet(payload)
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	if (!timesheet_id && timesheetLoading) {
		return <Spinner />
	}

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType="update">
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<TimeSelect
							id="time_on"
							title="Adjusted Start"
							onChange={formik.setFieldValue}
							value={formik.values.time_on}
							error={formik.errors.time_on}
						/>
					</div>
					<div className="w-1/2">
						<TimeSelect
							id="time_off"
							title="Adjusted Finish"
							onChange={formik.setFieldValue}
							value={formik.values.time_off}
							error={formik.errors.time_off}
						/>
					</div>
				</div>
				{/* <div className="flex items-center justify-between px-2">
					<div className="w-full">
						<Input
							id="lunch_break"
							title="Lunch Break"
							placeholder="Lunch Break"
							type="text"
							error={formik.errors.lunch_break}
							value={formik.values.lunch_break}
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
						/>
					</div>
				</div> */}

				<div className="flex items-center justify-between px-2">
					<div className="w-full">
						<TextArea
							id="comments"
							title="Sign In Comments"
							rows={4}
							type="text"
							error={formik.errors.comments}
							value={formik.values.comments}
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
						/>
					</div>
				</div>

				{/* Hours allocated 1. An editable text field with the heading on the left and the value on the right. */}
				<div className="flex items-center justify-between px-2">
					<div className="w-full">
						<Input
							id="hours1"
							title="1st Allocation Hours"
							placeholder="Lunch Break"
							type="number"
							error={formik.errors.hours1}
							value={formik.values.hours1}
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
						/>
					</div>
					{/* // 1st Allocation Comment */}
					<div className="w-full">
						<TextArea
							id="comments1"
							title="1st Allocation Comment"
							rows={4}
							type="text"
							error={formik.errors.comments1}
							value={formik.values.comments1}
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
						/>
					</div>
				</div>
				{/* Hours allocated 2. */}
				<div className="flex items-center justify-between px-2">
					<div className="w-full">
						<Input
							id="hours2"
							title="2nd Allocation Hours"
							placeholder="Lunch Break"
							type="number"
							error={formik.errors.hours2}
							value={formik.values.hours2}
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
						/>
					</div>

					{/* 2nd Allocation Comment */}
					<div className="w-full">
						<TextArea
							id="comments2"
							title="2nd Allocation Comment"
							rows={4}
							type="text"
							error={formik.errors.comments2}
							value={formik.values.comments2}
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
						/>
					</div>
				</div>
				{/* Hours allocated 3. */}
				<div className="flex items-center justify-between px-2">
					<div className="w-full">
						<Input
							id="hours3"
							title="3rd Allocation Hours"
							placeholder="Lunch Break"
							type="number"
							error={formik.errors.hours3}
							value={formik.values.hours3}
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
						/>
					</div>
					{/* 3rd Allocation Comment */}
					<div className="w-full">
						<TextArea
							id="comments3"
							title="3rd Allocation Comment"
							rows={4}
							type="text"
							error={formik.errors.comments3}
							value={formik.values.comments3}
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
						/>
					</div>
				</div>
			</SideModal>
		</>
	)
}
