import { useFormik } from 'formik'
import { Dropdown, Input, SideModal, Spinner } from 'common'
import { AssetServices } from 'services'
import * as Yup from 'yup'
import { OptionsForDropdown } from 'utilities'

interface IProps {
    asset_category?: string
    setOpen: (open: boolean) => void
    open: boolean
    heading: string

}

interface IinitialValues {
	category: string
	name: string

}


export const NewAssetTypes = ({ asset_category, setOpen, open, heading }: IProps) => {
    const { data: assetsCategoriesData, isLoading } = AssetServices.useAssetCategories() // Assuming you have a similar service for assets
    const { createAssetType } = AssetServices.useCreateAssetType()

	const initialValues: IinitialValues = {
		category: asset_category || '',
		name: '',
	}
	const validationSchema = Yup.object({})

	const formik = useFormik({
		initialValues,
		validationSchema,
        enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			await createAssetType(values)
			setSubmitting(true)
			formik.resetForm()
			setOpen(false)
		},
	})
    if (isLoading) {
        return <Spinner />
      }
	return (
		<SideModal
			heading={heading}
			open={open}
			setOpen={setOpen} // use setOpen from props
			handleSubmit={formik.handleSubmit}
			isLoading={isLoading}
			formType={'create'}
            >
            
			<div className="flex items-center justify-between px-2">
				<Dropdown
					id="category"
					label="Asset Category"
					onChange={formik.setFieldValue}
					value={formik.values.category}
					error={formik.errors.category}
					options={OptionsForDropdown(
                        assetsCategoriesData,
                        'id',
                        'category_name'
                    )}
				/>
			</div>
			<div className="flex items-center justify-between px-2">
				<Input
					id="name"
					title="Asset Type"
					type="text"
					placeholder="Asset Type"
					handleChange={formik.handleChange}
					handleBlur={formik.handleBlur}
					value={formik.values.name}
					error={formik.errors.name}
                    autoFocus={true}
				/>
			</div>
		</SideModal>
	)
}
