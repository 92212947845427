import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'

export const useCreateAssetType = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { putRequest } = useApi()
	const queryClient = useQueryClient()

	const createAssetType = async (assetType: unknown) => {
		try {
			const response = await putRequest(
				AppRoutes.serverAssetsRoutes.createAssetType,
				assetType
			)
			queryClient.refetchQueries(['assets_types'])
			showSuccess('Asset Type created successfully')
			return response
		} catch (error) {
			showError('Something went wrong creating Asset Type')
			throw new Error((error as Error).message)
		}
	}
	return { createAssetType }
}
