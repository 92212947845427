import { useState } from 'react'
import { FilterMatchMode } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { LeaveServices, DataTableServices } from 'services'
import { Container, DataTableHeader, PageHeading } from 'common'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { Column } from 'primereact/column'
import { Link, useLocation } from 'react-router-dom'
import { PencilIcon } from '@heroicons/react/24/solid'
import { AppRoutes } from 'config'
import { dateFormat } from 'utilities'
import { ApproveLeave } from 'components/Leave/ApproveLeave'
import { DeclineLeave } from 'components/Leave/DeclineLeave'
import { CreateLeaveForm } from 'components/Leave'

export const LeaveMainTable = () => {
	const location = useLocation()
	const { data, isLoading } = LeaveServices.useLeaves()
	const [openLeaveForm, setOpenLeaveForm] = useState(false)

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		dataTableReference,
		FilterColumn,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [
			{
				filterName: 'startDate_date',
				filterInitialValue: '',
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.date,
				filterOptions: [],
			},
			{
				filterName: 'endDate_date',
				filterInitialValue: '',
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.date,
				filterOptions: [],
			},
		],
		additionalGlobalFilterFields: ['staffData.staff_name', 'type'],
	})

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})

	return (
		<div className="card">
			<PageHeading
				title="Leave"
				createBtn="Create Leave"
				isEditable={false}
				setOpen={setOpenLeaveForm}
			/>
			<Container>
				<DataTable
					ref={dataTableReference}
					value={data}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					rowsPerPageOptions={[25, 50, 100]}
					loading={isLoading}
					dataKey="id"
					filters={filters}
					globalFilterFields={globalFilterFields}
					header={header}
					emptyMessage="No Leave found.">
					<Column
						field="createdAt"
						header="Date"
						body={(rowData) => dateFormat.format(new Date(rowData.createdAt))}
					/>
					<Column field="staffData.staff_name" header="Staff" />
					<Column field="type" header="Leave Type" />
					<Column
						field="startDate_date"
						header="Start Date"
						body={(rowData) => rowData.startDate}
						{...FilterColumn.startDate_date}
					/>
					<Column
						field="endDate_date"
						header="End Date"
						body={(rowData) => rowData.endDate}
						{...FilterColumn.endDate_date}
					/>
					<Column field="totalDays" header="Total Days" />
					<Column field="comments" header="Comments" />
					<Column
						field=""
						header="Approve"
						style={{ maxWidth: 50 }}
						body={(row) => (
							<ApproveLeave leaveId={row.id} status={row.status} />
						)}
					/>
					<Column
						field=""
						header="Decline"
						style={{ maxWidth: 50 }}
						body={(row) => (
							<DeclineLeave leaveId={row.id} status={row.status} />
						)}
					/>
					<Column
						field="id"
						header="Edit"
						body={(rowData) => (
							<Link
								to={`${AppRoutes.privateRoutes.leaveEdit.replace(
									':id',
									rowData.id.toString()
								)}`}
								state={{ background: location, name: 'editLeave' }}>
								<PencilIcon className="w-5 h-5" />
							</Link>
						)}
					/>
				</DataTable>
				<CreateLeaveForm
					formType="create"
					heading="Create Leave"
					open={openLeaveForm}
					setOpen={setOpenLeaveForm}
					leaveId={0}
				/>
			</Container>
		</div>
	)
}
